<template>
  <v-dialog
    v-model="viewModal"
    fullscreen
    scrollable
    transition="dialog-bottom-transition"
    content-class="mobile-dialog"
  >
    <v-card>
      <v-card-title class="card-title">
        <CustomButtonAction :btnBack="true" @onChangeBack="btnBack()" />
        <span class="headline">{{ edit ? "Editar" : "Registrar" }}</span>
      </v-card-title>

      <v-divider></v-divider>

      <v-card-text>
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-container>
            <v-row>
              <v-col cols="12" md="6">
                <v-text-field
                  color="second"
                  label="Cédula"
                  v-model="item.dni"
                  :autocomplete="'off'"
                  :rules="requiredRules"
                  :maxlength="15"
                  dense
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" md="6">
                <v-text-field
                  color="second"
                  label="Nombre"
                  v-model="item.name"
                  :autocomplete="'off'"
                  :rules="requiredRules"
                  :maxlength="100"
                  dense
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="6">
                <v-text-field
                  color="second"
                  label="Apellido"
                  v-model="item.lastName"
                  :autocomplete="'off'"
                  :rules="requiredRules"
                  :maxlength="100"
                  dense
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" md="6">
                <v-text-field
                  color="second"
                  label="Teléfono"
                  v-model="item.phone"
                  :autocomplete="'off'"
                  :rules="numberRules"
                  :maxlength="20"
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  color="second"
                  label="Correo electrónico"
                  v-model="item.email"
                  :autocomplete="'off'"
                  :rules="emailRules"
                  :maxlength="150"
                  dense
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12">
                <v-textarea
                  color="second"
                  :autocomplete="'off'"
                  label="Dirección"
                  v-model="item.address"
                  :rules="requiredRules"
                  :maxlength="1000"
                  dense
                  rows="3"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-btn
          block
          color="second"
          class="white--text"
          @click="apply()"
          :loading="loading"
        >
          {{ edit ? "Actualizar" : "Registrar" }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from "vuex";
import CustomButtonAction from "../../../../shared/components/custom-button-action/CustomButtonAction.vue";
import useValidate from "../../../../shared/validate/useValidate";
import ClientServices from "../../../../core/services/client-services";

export default {
  name: "EditClient",
  data: () => ({
    viewModal: false,
    valid: false,
    loading: false,
    item: {},
    listSeller: [],
    requiredRules: [useValidate["required"]],
    emailRules: [useValidate["email"]],
    numberRules: [useValidate["required"], useValidate["phone"]],
  }),
  components: {
    CustomButtonAction,
  },
  computed: {
    ...mapState(["user"]),
  },
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    edit: {
      type: Boolean,
      default: false,
    },
    data: {},
  },
  watch: {
    dialog: function (value) {
      this.viewModal = value;
    },
    edit: function (value) {
      if (value) {
        this.item = Object.assign({}, this.data);
      }
    },
  },
  created() {},
  methods: {
    btnDialog() {
      this.viewModal = true;
    },
    apply() {
      const validate = this.$refs.form.validate();
      if (validate) {
        if (this.edit) {
          this.btnEdit();
        } else {
          this.btnSave();
        }
      }
    },
    btnBack() {
      this.viewModal = false;
      this.$emit("update:dialog", false);
      this.$emit("update:edit", false);
      setTimeout(() => {
        this.$refs.form.resetValidation();
        // this.$refs.form.reset();
      }, 500);
    },
    async btnSave() {
      this.loading = true;
      const { saveClient } = ClientServices();
      this.item.seller = this.user._id;
      saveClient(this.item).then((resp) => {
        const response = resp.data;
        if (response.code == 201) {
          this.$toast.success("El cliente se ha registrado de forma exitosa");
          this.$emit("onChange", response.data);
          this.btnBack();
        }
        this.loading = false;
      });
    },
    btnEdit() {
      this.loading = true;
      const { updateClient } = ClientServices();

      this.item.seller = this.item.seller.name
        ? this.item.seller._id
        : this.item.seller;

      updateClient(this.item.id, this.item).then((resp) => {
        const response = resp.data;
        if (response.code == 201) {
          this.$toast.success("El cliente se ha actualizado de forma exitosa");
          this.$emit("onChange", response.data);
          this.btnBack();
        }
        this.loading = false;
      });
    },
  },
};
</script>
