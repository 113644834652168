<template>
  <div class="client-mobile">
    <v-container>
      <v-row class="row-search">
        <v-col cols="12">
          <v-text-field
            color="second"
            prepend-inner-icon="mdi-magnify"
            placeholder="Buscar ..."
            solo
            dense
            v-model="search"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row v-if="clients.length === 0">
        <v-col cols="12">
          <div class="message-error">No tiene clientes registrados</div>
        </v-col>
      </v-row>

      <v-row v-for="(item, i) in listFilter(clients)" :key="i">
        <v-col cols="12">
          <v-card>
            <!-- <v-card-title class="title">
              {{ item.businessName }}
            </v-card-title> -->
            <v-card-text>
              <table>
                <tr>
                  <td class="col-td">
                    <v-icon>mdi-badge-account-horizontal-outline</v-icon>
                  </td>
                  <td class="col-td-2">{{ item.dni | unitMile }}</td>
                </tr>
                <tr>
                  <td class="col-td"><v-icon>mdi-account-outline</v-icon></td>
                  <td class="col-td-2">{{ item.name }} {{ item.lastName }}</td>
                </tr>
                <tr>
                  <td class="col-td"><v-icon>mdi-phone-outline</v-icon></td>
                  <td class="col-td-2">{{ item.phone }}</td>
                </tr>
                <tr v-if="item.email">
                  <td class="col-td"><v-icon>mdi-email-outline</v-icon></td>
                  <td class="col-td-2">{{ item.email }}</td>
                </tr>
                <tr>
                  <td class="col-td"><v-icon>mdi-map-outline</v-icon></td>
                  <td class="col-td-2">{{ item.address }}</td>
                </tr>
              </table>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
              <div class="btnEdit">
                <v-btn small outlined color="four" @click="btnEdit(item)">
                  <v-icon>mdi-pencil-outline</v-icon> Editar
                </v-btn>
                <v-btn
                  small
                  outlined
                  color="four"
                  @click="btnDelete(item)"
                  :loading="loaderDelete"
                >
                  <v-icon>mdi-delete-outline</v-icon> Eliminar
                </v-btn>
              </div>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>

      <v-btn
        fab
        fixed
        bottom
        right
        class="btnAddClient white--text"
        color="primary"
        @click="addItem()"
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </v-container>
    <EditClient
      :dialog.sync="editModal"
      :edit.sync="editView"
      :data="selectItem"
      @onChange="firstRowData($event)"
    />

    <CustomDialog
      :dialog.sync="deleteModal"
      :title="'Confirmar eliminación'"
      :message="'¿Estás seguro que quieres eliminar el registro?'"
      :maxWidth="'320'"
      @onChange="btniItemDelete()"
    ></CustomDialog>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import EditClient from "./edit-client.vue";
import CustomDialog from "../../../../shared/components/custom-dialog/CustomDialog.vue";
import ClientService from "../../../../core/services/client-services";
import ClientStorage from "../../../../core/storage/client-storage";
import useSearch from "../../../../shared/filters/useSearch";

export default {
  name: "Client",
  data: () => ({
    editView: false,
    editModal: false,
    deleteModal: false,
    selectItem: {},
    list: [],
    search: "",
    loaderDelete: false,
  }),
  components: {
    EditClient,
    CustomDialog,
  },
  computed: {
    ...mapState(["clients"]),
  },
  created() {
    this.getListClientService();
  },
  methods: {
    ...mapActions(["getListClients", "addClient", "getListClientService"]),
    listFilter(data) {
      return useSearch({
        data: data,
        search: this.search,
        modulo: "client",
      });
    },
    addItem() {
      this.editModal = true;
    },
    btnEdit(item) {
      this.editModal = true;
      this.editView = true;
      this.selectItem = item;
    },
    firstRowData(data) {
      const { clientAdd } = ClientStorage();
      clientAdd(data);
      this.getListClients();
    },
    // getListClient() {
    //   const { getClientFromSellerId } = ClientService();
    //   const { clientAdd } = ClientStorage();
    //   getClientFromSellerId().then((resp) => {
    //     const response = resp.data;
    //     if (response.code === 200) {
    //       response.data.map((item) => {
    //         clientAdd(item);
    //       });
    //       this.getListClients();
    //     }
    //   });
    // },
    btnDelete(item) {
      this.deleteModal = true;
      this.selectItem = item;
    },
    btniItemDelete() {
      const { deleteClientId } = ClientService();
      const { clientDeleteById } = ClientStorage();
      this.loaderDelete = true;
      deleteClientId(this.selectItem.id).then((resp) => {
        const response = resp.data;
        if (response.code == 200) {
          clientDeleteById(this.selectItem.id);
          this.getListClients();
          this.$toast.success("El registro ha sido eliminado exitosamente");
        }
        this.loaderDelete = false;
      });
    },
  },
};
</script>

<style lang="scss">
.client-mobile {
  // height: calc(100vh - 104px);

  .row-search {
    margin-bottom: -35px;
  }

  .title {
    font-size: 16px !important;
    padding: 8px 16px 2px;
    word-break: normal;
  }

  .row + .row {
    margin-top: auto;
  }

  .btnAddClient {
    margin-bottom: 3.5rem;
  }

  table {
    padding: 0px 7px;
    width: 100%;
  }

  .col-td {
    width: 36px;
    height: 28px;
    color: var(--color-second);

    i {
      font-size: 22px;
    }
  }

  .col-td-2 {
    // display: flex;
    align-items: center;
    font-size: 15px;
    // height: 28px;
    color: var(--color-second);
  }

  .btnEdit {
    display: flex;
    justify-content: flex-start;
    width: 100%;

    i {
      font-size: 18px;
      margin-right: 5px;
    }
  }

  .btnEdit button:nth-child(1) {
    margin-right: 5px;
  }

  .message-error {
    text-align: center;
    font-size: 18px;
    opacity: 0.4;
    margin-top: 10px;
  }
}
</style>
